export enum PathType {
	Villages = 'poselki',
	Village = 'poselok',
	Plot = 'uchastok',
	House = 'dom',
	Cottage = 'kottedzh',
	Townhouse = 'taunhaus',
	Duplex = 'dupleks',
	Buy = 'kupit',
	Objects = 'obekty',
	Developers = 'zastroyschiki',
	Developer = 'zastroyschik',
	Documents = 'documenty',
	Instruction = 'instruktsiya'
}